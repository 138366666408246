search input:

<template>
  <div
    class="pa-md-5 w-100 autocomplete d-flex flex-row"
    :class="modify ? '' : 'has-max-width'"
  >
    <div class="w-100" v-if="modify">
      <v-combobox
        class="search-input"
        clearable
        filled
        solo
        dense
        v-model="selected"
        ref="autocomplete"
        :items="getItems"
        :search-input.sync="search"
        no-filter
        :placeholder="$t('navbar.searchPlaceholder')"
        flat
        item-text="name"
        item-value="name"
        hide-details
        hide-no-data
        return-object
        type="search"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.native.prevent.stop="doSearch"
      >
        <template slot="append">
          <v-icon
            x-large
            aria-label="Cerca"
            @click.prevent.stop="doSearch"
            color="default"
            >$search</v-icon
          >
        </template>
      </v-combobox>
    </div>
    <div class="w-100" v-else>
      <v-combobox
        class="search-input pa-5"
        clearable
        filled
        solo
        dense
        v-model="selected"
        ref="autocomplete"
        :items="getItems"
        :search-input.sync="search"
        no-filter
        :placeholder="$t('navbar.searchPlaceholder')"
        flat
        item-text="name"
        item-value="name"
        hide-details
        hide-no-data
        return-object
        type="search"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.native.prevent.stop="doSearch"
        :menu-props="{
          closeOnContentClick: true,
          contentClass: 'searches-menu mt-4'
        }"
      >
        <template v-slot:prepend-item>
          <category-block
            v-if="search && searchCategory"
            :target="searchCategory"
            position="positionmenu1"
            class="category-block-menu category-block-menu-header"
          />
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex w-100">
            <v-list-item-icon v-if="item.type == 'Ricerche'">
              <v-icon
                color="primary"
                :aria-label="$t('common.close')"
                :title="$t('common.close')"
                @click.prevent.stop="deleteWord(item)"
              >
                $clear
              </v-icon>
            </v-list-item-icon>
            <!-- <v-list-item-icon v-else-if="item.type == 'FastSearch'">
            <v-icon color="primary" small>$info</v-icon>
          </v-list-item-icon> -->

            <template v-if="item.productId">
              <ProductSearchRow
                :modify="modify"
                :product="item"
                @goToProductDetail="goToProductDetail"
              />
            </template>
            <v-list-item-content v-else @click="doSearch(item)">
              <v-list-item-title>
                <!-- <span
                class="font-weight-bold primary--text text-body-2"
                v-if="item.type == 'FastSearch'"
              >
                {{ $t("fastSearch.searchInput.label") }}
              </span> -->
                <text-highlight
                  :queries="search ? search : '*'"
                  :class="{ 'font-weight-bold': item.type == 'link' }"
                >
                  {{ item.name }}
                </text-highlight>
              </v-list-item-title>
              <!-- <v-list-item-subtitle v-if="item.type == 'Categorie'">
                <v-badge
                  inline
                  v-if="item.count"
                  :content="item.count"
                  color="secondary"
                >
                  {{ $t("productSearch.search") }}
                  <strong>{{ search }}</strong> {{ $t("productSearch.in") }}
                  {{ item.name }}
                </v-badge>
              </v-list-item-subtitle> -->
              <v-list-item-subtitle v-if="item.type == 'Marche'">
                {{ $t("productSearch.search") }} <strong>{{ search }}</strong>
                {{ $t("productSearch.brand") }} {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </template>
        <template v-slot:append-item>
          <category-block
            v-if="search && searchCategory"
            :target="searchCategory"
            position="positionmenu2"
            class="category-block-menu category-block-menu-footer"
          />
        </template>
        <template slot="append">
          <v-icon
            aria-label="Cerca"
            @click.prevent.stop="doSearch"
            color="default"
            >$search</v-icon
          >
        </template>
      </v-combobox>
    </div>
  </div>
</template>
<style lang="scss">
.is-cordova {
  .search-input {
    border: none !important;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .autocomplete {
    max-width: 100% !important;
  }
  .search-input {
    .v-input__control {
      height: 45px !important;
      .v-input__slot {
        height: 45px !important;
      }
    }
  }
}
.autocomplete {
  width: 100%;
  // max-width: 500px;
}
.search-input {
  // border: 1px solid var(--v-primary-lighten2);
  // border-radius: 4px;
  // max-width: 926px;
  .v-input__control {
    display: inline;
    height: 60px;
    .v-input__slot {
      height: 60px;
      padding: 20px !important;
      background-color: var(--v-grey-lighten1) !important;
    }
  }
}
.ecommerce .search-input {
  border-radius: 9999px;
  .v-input__control .v-input__slot {
    background-color: var(--v-grey-lighten3) !important;
  }
}

.searches-menu {
  &.v-menu__content {
    max-height: 80vh !important;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      //  min-width: 330px !important;
    }
  }
  .v-list-item {
    .v-list-item__content {
      padding: 6px 0;
    }
    .v-list-item__title {
      font-size: 0.875rem;
    }
  }
  .v-subheader {
    font: normal normal bold 14px/44px Montserrat;
    white-space: nowrap;
    background-color: var(--v-grey-lighten1);
    padding: 0 16px;
  }
  .text__highlight {
    background: transparent;
    font-weight: bold;
  }
}
.is-cordova {
  .search-input {
    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          @media #{map-get($display-breakpoints, 'xs-only')} {
            input {
              font-size: 14px;
            }
            .icon-lente {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<script>
//import Barcode from "./Barcode";

import FastSearchMenu from "./FastSearchMenu.vue";
import ProductSearchRow from "@/components/product/ProductSearchRow.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";
import CategoryService from "~/service/categoryService";
import cmService from "@/commons/service/mksCmService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";
import { buildArticleRoute } from "@/service/functions";

export default {
  components: {
    ProductSearchRow
    // Barcode
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      firstSuggest: null,
      search: null,
      searchCategory: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      }
    };
  },
  props: {
    modify: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      return result;
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    clearSearchBar() {
      let _this = this;
      setTimeout(function() {
        _this.selected = "";
      }, 80);
    },
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    // FETCH ORIGINAL
    fetchSuggestion: debounce(async function() {
      let _this = this;
      _this.loading = true;
      if (_this.search) {
        let newItems = [];
        _this.firstSuggest = null;

        let response = await ProductService.suggest(_this.search.trim());
        let tempItems = [];
        if (response.length > 0) {
          let prevType = "none";
          let suggestCount = 0;
          let categoryCount = 0;
          const excludedTypes =
            {
              sait: ["Categorie", "Suggerimenti"],
              clickandcoop: ["Rivista e News"]
            }[_this.mainTenantName] || [];

          forEach(response, function(value) {
            if (
              value.type !== prevType &&
              !excludedTypes.includes(value.type)
            ) {
              tempItems.push({ header: value.type });
              prevType = value.type;
            }
            if (_this.mainTenantName == "clickandcoop") {
              if (value.type == "Suggerimenti" && suggestCount < 3) {
                if (!_this.firstSuggest) _this.firstSuggest = value.name;
                tempItems.push(value);
                suggestCount++;
              }
              if (value.type == "Categorie" && categoryCount < 1) {
                tempItems.push(value);
                categoryCount++;
              }
            }
          });
        }

        // load category search to show proposal in menu
        _this.searchCategory = await CategoryService.getCategoryBySlug(
          "search",
          false,
          _this.search
        );

        // On the first stage of the site, which is an 'institutional site'
        // rather than a full 'ecommerce site,' we have commented out this API call.
        // load first 3 products to show a preview in menu
        if (_this.mainTenantName == "clickandcoop") {
          if (_this.firstSuggest) {
            let res = await ProductService.search({
              q: _this.firstSuggest,
              page: 1,
              page_size: 3,
              details: true
            });
            if (res.products) {
              newItems.push({ header: "Prodotti" });
              forEach(res.products, function(value) {
                newItems.push(value);
              });
            }
          }
        }

        newItems = [...newItems, ...tempItems];
        // load post and recupe from wp
        if (_this.mainTenantName == "sait") {
          cmService
            .searchArticle({
              q: this.search.trim(),
              parent_article_type_id: [1, 2, 3],
              page_size: 6
            })
            .then(function(response) {
              if (response.data.articles.length > 0) {
                newItems.push({ header: "Rivista e News" });
                forEach(response.data.articles, function(value) {
                  newItems.push({
                    name: value.title,
                    type: "Rivista e News",
                    ...value
                  });
                });
                newItems.push({
                  type: "link",
                  name: "Cerca tra Rivista e News >"
                });
              }
            });
        }
        _this.loading = false;
        _this.items = newItems;
        /** Emit the results only if searchInput is opnened on OrderEdit */
        if (this.modify) {
          global.EventBus.$emit("searchResults", _this.items);
        }
      }
    }, 200),

    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      currentQuery.q = this.search;
      this.saveWord(currentQuery.q);
      4;
      const isClickAndCoop = this.mainTenantName == "clickandcoop";
      const isSait = this.mainTenantName == "sait";

      if (isClickAndCoop || isSait) {
        if (item.type === "Suggerimenti" || item.type === "Ricerche") {
          currentQuery.q = item.name;
        } else if (item.type === "Categorie" || item.type === "Marche") {
          currentQuery.q =
            item.type === "Categorie" ? this.firstSuggest : this.search;
          if (item.type === "Categorie") {
            currentQuery.parent_category_id = parseInt(item.id);
            currentQuery.filter = item.name;
          } else if (item.type === "Marche") {
            currentQuery.parent_vendor_id = item.id;
            currentQuery.filter = item.name;
          }
        } else if (item.type === "FastSearch") {
          currentQuery.q = "";
          this.clearSearchBar();
        } else if (this.search != null) {
          currentQuery.q = this.search;
          this.saveWord(currentQuery.q);
        }

        // Additional condition for Sait
        if (
          isSait &&
          (item.type === "Rivista e News" || item.type === "link")
        ) {
          currentQuery.q = this.search;
        }
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        this.$emit("didSearch");
        //this.$store.dispatch("category/resetFilters");
        if (!this.modify) {
          global.EventBus.$emit("resetFilters");
          if (item.type == "Rivista e News") {
            const to = buildArticleRoute(item);
            this.$router.push(to);
          } else if (this.mainTenantName == "clickandcoop") {
            this.$router.push({
              path: "/search",
              name: "Search",
              query: currentQuery
            });
          } else {
            this.$router.push({
              name: "CmSearch",
              query: currentQuery
            });
          }
        } else {
          global.EventBus.$emit("resetFilters");
          global.EventBus.$emit("alternativeSearch", currentQuery);
        }
        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },

    handleHeaderHeight() {
      const headerContainer = this?.$refs?.autocomplete?.$refs.menu;
      if (headerContainer) {
        headerContainer.isActive = false;
        this.$nextTick(() => {
          const topVal = window.getComputedStyle(
            document.querySelector(".v-main")
          ).paddingTop;
          this.$refs.autocomplete.$refs.menu.$el.style.top = `${topVal} !important`;
        });
      }
    },
    goToProductDetail(product) {
      this.$router.push({ name: "Product", params: { slug: product.slug } });
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      this.clearSearchBar();
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    this.handleHeaderHeight();

    window.addEventListener("scroll", this.handleHeaderHeight);
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
